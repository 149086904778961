import { Suspense, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import portals from "constants/portals";
import { useDispatch, useSelector } from "react-redux";
import { refreshToken } from "store/login/loginSlice";
import {
  checkContentAdminRole,
  checkLukeContentRole,
} from "helpers/roleChecker";

// for QA and Content permission
export const checkQAContentRole = access_token => {
  if (!access_token) return false;
  const decoded = jwt_decode(access_token);
  const { role } = decoded;
  const roles = JSON.parse(role);
  return portals.some(portal => portal.roleChecker(roles) === true);
};

// for Luke only permission
export const checkLukeRole = access_token => {
  if (!access_token) return false;
  const decoded = jwt_decode(access_token);
  const { role } = decoded;
  const roles = JSON.parse(role);
  return checkLukeContentRole(roles);
};

// for Content only permission
export const checkContentRole = access_token => {
  if (!access_token) return false;
  const decoded = jwt_decode(access_token);
  const { role } = decoded;
  const roles = JSON.parse(role);
  return checkContentAdminRole(roles);
};

const RouteRequiresLogin = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const access_token = localStorage.getItem("access_token");

  const isLoggedIn = checkQAContentRole(access_token);
  const { userID } = useSelector(state => state.login);
  useEffect(() => {
    if (userID === null) {
      dispatch(refreshToken());
    }
  }, [dispatch, userID]);

  return (
    <Route
      {...rest}
      render={props => {
        return isLoggedIn ? (
          <Suspense fallback={<div />}>
            <Component {...props} />
          </Suspense>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default RouteRequiresLogin;
